import React from "react"
import {BlogContainer, SubTitleSection,Text } from "../../components/atomics"
import {Link} from "gatsby";
import Page from "../../components/Pages/Page";

const Cursos = () => {
  const cursos = [
    {
      url: '/curso-domine-a-arte-da-produtividade/?utm_source=website&utm_medium=cursos&utm_campaign=curso',
      title: 'Domine a arte da produtividade',
      description:'Descubra como ser mais produtivo e gerir melhor seu tempo para alcançar os seus objetivos - sem lista interminável de tarefas e com muito mais tempo livre para fazer o que quiser. 🚀',
      img: require('../../assets/images/cursoProdutividade.png')
    },
    {
      url: '/curso-domine-seus-habitos/?utm_source=website&utm_medium=cursos&utm_campaign=curso',
      title: 'Domine seus hábitos: A chave para uma vida extraordinária',
      description:`Desbloqueie seu verdadeiro potencial e construa uma vida extraordinária baseada em hábitos poderosos. Sua vida extraordinária espera por você! ✨`,
      img: require('../../assets/images/cursoHabitos.png')
    },
  ];
  const description="Os melhores cursos para você mudar de vida e alcançar seus objetivos!"
  
  return(
  <Page title={'Cursos'} imageUrl={require('../../assets/images/cursoHabitos.png')} description={description+ ' Cursos: Domine seus hábitos: A chave para uma vida extraordinária'} pageId="cursos">
    <BlogContainer>
      {/* <Text className="text-description">{description}</Text>
      <div className="border"/> */}
      
      <div className="posts">
      {
        cursos.map(
          post=> (
            <Link to={post.url} className="post">
              <div>
                <img src={post.img} style={{height:300, marginBottom:10}} alt={post.title} />
                <SubTitleSection className="title-blog">
                  {post.title}
                </SubTitleSection>
                <Text className="text-description shadow" style={{marginTop:5}}>
                  {post.description}
                </Text>
              </div>
            </Link>
          ))
        }
      </div>
    </BlogContainer>
    <br/>
    <br/>
  </Page>
)}

export default Cursos
